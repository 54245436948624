import { HStack, Stack, VStack, WhiteCard } from '@elkaso-app/web-design';
import {
  MonthlyOrdersPricedData,
  useGetOrdersMonthlyPricedGmvApi,
} from 'apis/analytics/use-get-orders-monthly-priced-gmv-api';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React, { useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import {
  MonthlyOrdersNotPricedData,
  useGetOrdersMonthlyNotPricedGmvApi,
} from '../../../apis/analytics/use-get-orders-monthly-not-priced-gmv-api';
import { NotPricedPerCountryOrdersCountBarChart } from '../components/not-priced-per-country-orders-count-bar-chart';
import { PricedPerCountryOrdersCountBarChart } from '../components/priced-per-country-orders-count-bar-chart';
import { PricedPerCountryOrdersGmvBarChart } from '../components/priced-per-country-orders-gmv-bar-chart';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const generateColor = () => Math.floor(Math.random() * 127 + 127);

export const AnalyticsOrderDashboardScreen = () => {
  const dispatch = useAppDispatch();
  const { data: orderMonthlyPricedGMVData } = useGetOrdersMonthlyPricedGmvApi();
  const { data: orderMonthlyNotPricedGMVData } = useGetOrdersMonthlyNotPricedGmvApi();

  useEffect(() => {
    dispatch(changeTitle('sidebar.analytics'));
  }, []);

  interface OrdersChartDataProps {
    pricedData: MonthlyOrdersPricedData[];
    notPricedData: MonthlyOrdersNotPricedData[];
  }

  const ordersChartDataProps = React.useMemo((): OrdersChartDataProps => {
    return {
      pricedData: (orderMonthlyPricedGMVData || []) as MonthlyOrdersPricedData[],
      notPricedData: (orderMonthlyNotPricedGMVData || []) as MonthlyOrdersNotPricedData[],
    };
  }, [orderMonthlyPricedGMVData, orderMonthlyNotPricedGMVData]);

  const rgbColor = `${generateColor()}, ${generateColor()}, ${generateColor()}`;
  const pieData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of ',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          `rgba(${rgbColor}, 0.2)`,
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          `rgba(${rgbColor}, 1)`,
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <HStack maxWidth={'full'} overflow={'hidden'} spacing='md' align='stretch' h='full'>
      <WhiteCard display={'flex'} flexDirection={'column'} width={'full'} overflow={'auto'}>
        <VStack padding={3} align='normal' h='full'>
          <HStack>
            <PricedPerCountryOrdersCountBarChart
              data={ordersChartDataProps.pricedData}
              title={'Priced external orders monthly count stacked by countries'}
            />
            <NotPricedPerCountryOrdersCountBarChart
              data={ordersChartDataProps.notPricedData}
              title={'Not priced external orders monthly count stacked by countries'}
            />
          </HStack>
          <HStack justify='space-between'>
            <PricedPerCountryOrdersGmvBarChart
              data={ordersChartDataProps.pricedData}
              title={'External orders monthly GMV stacked by countries'}
            />
          </HStack>

          <HStack spacing='lg' align='center' justify='space-evenly'>
            <Stack width={'full'}>
              <Pie
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'right' as const,
                    },
                    title: {
                      display: true,
                      text: 'Orders',
                    },
                  },
                }}
                data={pieData}
              />
            </Stack>
            <Stack spacing='md' width={'full'}></Stack>
          </HStack>
        </VStack>
      </WhiteCard>
    </HStack>
  );
};
