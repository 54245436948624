import { Layout, Section } from 'layout';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AnalyticsOrderDashboardScreen } from './screens/analytics-order-dashboard-screen';
import { AnalyticsTransferDashboardScreen } from './screens/analytics-transfer-dashboard-screen';

const AnalyticsPage = () => {
  const match = useRouteMatch();

  return (
    <Layout type={'sidebar_page_layout'}>
      <Section name={'content'}>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/orders/dashboard`} />
          <Route path={`${match.url}/orders/dashboard`} component={AnalyticsOrderDashboardScreen} />
          <Route path={`${match.url}/transfers/dashboard`} component={AnalyticsTransferDashboardScreen} />
          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};

export default AnalyticsPage;
